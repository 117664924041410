.Authentication {
  width: 400px;

  button {
    margin-top: 10px;
    margin-right: 5px;
  }

  .Goto {
    span {
      padding-top: 7px;
      display: inline-block;
      vertical-align: middle;
      color: #777777;
    }
  }
}
