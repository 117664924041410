/**
 * Submenu
 */
.submenu__children {
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.3s;
}

.submenu__children--open {
  max-height: 25rem;
}

.submenu__icon {
  transition: transform 0.3s;
  user-select: none;
}

.submenu__icon--open {
  transform: rotate(90deg);
}

.submenu__children .mdc-list-item {
  text-indent: 1.5rem;
}
