.DateChooser {
  .mdc-text-field, .mdc-select {
    vertical-align: top;
  }

  .mdc-text-field {
    margin-top: 4px !important;
    margin-left: 10px;
  }

  .DateChooserDay {
    width: 70px;
  }

  .DateChooserYear {
    width: 100px;
  }
}
