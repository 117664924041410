.HomePage {
  padding: 10px;

  .FirebaseToken, .Account, .Messaging {
    margin-top: 10px;
  }

  .FirebaseToken {
    textarea {
      width: 400px;
      height: 100px;
      display: block;
      margin-bottom: 5px;
    }

    .TokenCopiedMessage {
      margin-left: 5px;
    }
  }
}
