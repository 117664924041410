@import '~@material/dialog/dist/mdc.dialog.css';
@import '~@material/drawer/dist/mdc.drawer.css';
@import '~@material/linear-progress/dist/mdc.linear-progress.css';
@import '~@material/menu/dist/mdc.menu.css';
@import '~@material/snackbar/dist/mdc.snackbar.css';
@import '~@material/top-app-bar/dist/mdc.top-app-bar.css';

@import './Colors.scss';

:root {
  --mdc-theme-primary: rgb(175, 166, 204);
  --mdc-theme-secondary: rgb(70, 70, 70);
  --mdc-theme-background: rgb(243, 243, 243);
}

html, body, #root {
  min-height: 100%;
}

body {
  margin: 0;
  padding: 0;
}

.mdc-top-app-bar {
  background: linear-gradient(to right, $purple, $lightgray);

  .mdc-top-app-bar__title {
    cursor: pointer;
  }
}

.Content {
  padding-top: 64px;

  .Page {
    padding: 10px;
    flex: 1 1 auto;
    max-width: 100%;
    transition: margin-left 0.3s, max-width 0.3s;
    will-change: margin-left;
    will-change: max-width;
  }
}

.mdc-drawer__drawer {
  z-index: 1000;
  position: fixed;
  top: 64px;
  left: 0;
  height: calc(100vh - 64px);
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.mdc-drawer--open {
  background-color: $lightgray;
  border: none;
}

.mdc-drawer--open + .Page {
  margin-left: 260px;
  max-width: calc(100% - 260px);
}

button {
  i.left {
    margin-right: 10px;
  }
  i.right {
    margin-left: 10px;
  }
}

.FormField {
  width: 100%;
  .mdc-text-field, .mdc-select {
    width: 100%;
  }

  // TODO: figure out why some shit like this is still purple
  // https://material.io/develop/web/docs/theming/
  .mdc-floating-label--float-above {
    color: $purple !important;
  }
}

.FormTitle {
  color: #666666;
}

.HeaderSignedInAs {
  color: $darkgray;
  margin-right: 10px;
}

.VerticallyCenteredPage {
  min-height: 100vh;
}

.VerticallyCenteredPage, .CenteredPage {
  display: flex;
  align-items: center;
  justify-content: center;

  .Centered {
    -webkit-box-flex: 0;
    -webkit-flex: none;
    -ms-flex: none;
    flex: none;
    min-width: 400px;
    max-width: 50%;
    background-color: white;
    padding: 20px;
    border-radius: 2px;
  }
}

.AuthenticationPage, .SigninPage {
  background: linear-gradient(to right, $purple, $lightgray);
}

.GradientButton {
  background-image: -webkit-gradient(linear,right top,left top,from(#5dbff8),to(#9597fc));
  background-image: -o-linear-gradient(right,#5dbff8 0,#9597fc 100%);
  background-image: linear-gradient(270deg,#5dbff8 0,#9597fc);
}
