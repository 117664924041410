.SessionLoadingPage {
  .SessionLoadingWrapper {
    position: fixed;
    height: 200px;
    width: 200px;
    overflow: show;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    .SessionLoadingMessage {
    }
  }
}
