.imgPreview {
    text-align: center;
    margin: 5px 15px;
    height: 200px;
    width: 200px;
    border-left: 1px solid #808080;
    border-right: 1px solid #808080;
    border-top: 1px solid #808080;
    border-bottom: 1px solid #808080;
}

.imgPreview img {
    width: 100%;
    height: 100%;
}

.none {
  display: none;
}

#file_input_text_div {
  margin-top: -8px;
  margin-left: 5px;
}
