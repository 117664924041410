@import '../Application/Colors.scss';

.ImageUploader {
  .ImageUploaderDropAreaMulti {
    width: 300px;
    min-height: 120px;
  }
  .ImageUploaderDropAreaSingle {
    width: 120px;
    height: 120px;
  }

  .ImageUploaderDropArea {
    position: relative;
    border: 1px dashed $purple;
    padding: 4px;

    .ImageUploaderPrompt {
      text-align: center;
      margin-top: 10px;
    }

    input[type="file"] {
      display: none;
    }

    .ImageUploaderUploaders:after {
      content: "";
      display: table;
      clear: both;
    }

    .ImageUploaderButtons {
      margin-top: 10px;
      text-align: center;

      .ImageUploaderUploadFiles {
        margin-left: 10px;
      }
    }
  }

  .ImageUploaderDropArea.Hovering {
    border: 1px solid $purple;
    background-color: rgba($purple, 0.5);
  }

}

$imageSize: 120px;

.ImageUploaderPlaceholder {
  width: $imageSize;
  height: $imageSize;
  float: left;

  padding-right: 2px;
  padding-bottom: 2px;

  .ImageUploaderPlaceholderLoading,
  .ImageUploaderPlaceholderLoaded,
  .ImageUploaderPlaceholderUploading,
  .ImageUploaderPlaceholderUploaded {
    width: $imageSize;
    height: $imageSize;

    .ImageUploaderPlaceholderThumbnail {
      position: relative;
      img {
        width: $imageSize;
        height: $imageSize;
        object-fit: cover;
      }
      .ImageUploaderPlaceholderRemove {
        position: absolute;
        top: 4px;
        right: 4px;
        z-index: 10;
        color: $deleteRed;
        cursor: pointer;
      }
      .ImageUploaderPlaceholderCloud {
        position: absolute;
        top: 4px;
        left: 4px;
        z-index: 10;
        color: white;
        text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
      }
    }
  }

  .ImageUploaderPlaceholderLoading {
    background-color: #999;
    border: 1px dashed #777;
  }

  .ImageUploaderPlaceholderLoading, .ImageUploaderPlaceholderUploading {
    position: relative;
    width: $imageSize;
    height: $imageSize;

    .ImageUploaderPlaceholderProgress {
      position: absolute;
      bottom: 0;
      left: 5px;
      height: 20px;
      width: $imageSize - 10;
      z-index: 10;
    }
  }
}

// .SingleImageUploader {
//   margin-top: 10px;
//   position: relative;
//
//   .SingleImageUploaderPreview {
//     img {
//       position: absolute;
//       top: 0;
//       left: 0;
//     }
//
//     button {
//       position: absolute;
//       left: 5px;
//       bottom: 5px;
//     }
//   }
//
//   .SingleImageUploaderForm {
//     border: 1px dashed #999999;
//
//     .SingleImageUploaderInput {
//       z-index: 10;
//       cursor: pointer;
//       opacity: 0;
//     }
//
//     .SingleImageUploaderInput, .SingleImageUploaderPrompt {
//       position: absolute;
//       top: 0;
//       left: 0;
//     }
//
//     .SingleImageUploaderPrompt {
//       display: block;
//       text-align: center;
//       color: #999999;
//     }
//   }
// }
