.DevicePairingPage {
  .FormField {
    width: 200px;
    margin-bottom: 10px;
  }

  .AsyncButtonLoader {
    margin-top: 2px;
    width: 150px;
  }

  .PairingFinish {
    margin-top: 20px;

    button {
      margin-top: 5px;
    }
  }

  .PairingStart {
    margin-top: 20px;
  }

  .DevicePairing {
    margin-bottom: 5px;
  }
}
