.imgPreview {
    text-align: center;
    margin: 5px 15px;
    height: 200px;
    width: 200px;
    border-left: 1px solid #808080;
    border-right: 1px solid #808080;
    border-top: 1px solid #808080;
    border-bottom: 1px solid #808080;
}

.imgPreview img {
    width: 100%;
    height: 100%;
}

.none {
  display: none;
}

#file_input_text_div {
  margin-top: -8px;
  margin-left: 5px;
}

.Experience {
  img.preview {
    display: block;
    width: 360px;
  }
}

.AvailableOn {
  margin-top: 10px;
}

.ExperienceDay {
  .ExperienceImage {
    img {
      width: 64px;
    }
  }
}

.Schedule {
  img {
    width: 64px;
    cursor: pointer;
  }

  .ScheduleExperienceTypeSelect {
    margin-bottom: 10px;
  }

  .ScheduleTabs {
    &::after {
      content: "";
      clear: both;
      display: table;
    }

    .mdc-tab {
      float: left;
    }
  }

  .ScheduleItem {
    background-color: #cfc;
  }

  th, td {
    padding: 4px;
  }

  .Backfilled {
    background-color: #ddd;
  }

  .Today {
    background-color: rgb(175, 166, 204);
    color: white;
  }

  .UnavailabilityCause {
    background-color: #fcc;
  }

  .ScheduleExperienceEdit {
    background-color: white;

    .AsyncButton, Button {
      margin: 4px;
      display: inline-block;
    }
  }
}
